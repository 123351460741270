import createSlider from '@/js/helpers/create-slider'
import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const sliderEls = document.querySelectorAll('[data-slider]')
  if (sliderEls) {
    for (let i = 0; i < sliderEls.length; i++) {
      createSlider(sliderEls[i])
    }
  }
})
