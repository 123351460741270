import docReady from '@/js/helpers/doc-ready'

function newMap (el) {
  const markers = el.querySelectorAll('.marker')
  const args = {
    zoom: 16,
    center: {
      lat: +markers[0].getAttribute('data-lat'),
      lng: +markers[0].getAttribute('data-lng')
    },
    mapTypeId: google.maps.MapTypeId.ROADMAP
  }

  const map = new google.maps.Map(el, args)
  map.markers = []

  for (let i = 0; i < markers.length; i++) {
    addMarker(markers[i], map)
  }

  return map
}

function addMarker (m, map) {
  const lat = m.getAttribute('data-lat')
  const lng = m.getAttribute('data-lng')
  const latLng = {
    lat: parseFloat(lat),
    lng: parseFloat(lng)
  }

  const marker = new google.maps.Marker({
    position: latLng,
    map
  })

  map.markers.push(marker)
}

docReady(() => {
  const maps = document.querySelectorAll('[data-map]')
  if (maps && maps.length) {
    for (let i = 0; i < maps.length; i++) {
      // eslint-disable-next-line
      const map = newMap(maps[i])
    }
  }
})
