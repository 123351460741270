import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const buttons = document.querySelectorAll('.button, .arrow-link')
  const blockButtons = document.querySelectorAll('.block-button')
  const baseUrl = window.location.protocol + '//' + window.location.hostname

  if (buttons.length) {
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener('click', () => {
        const text = buttons[i].innerText || buttons[i].textContent
        const href = buttons[i].href ? buttons[i].href : 'no-link'
        const current = window.location.href.replace(baseUrl, '')

        window.gtag('event', 'click', {
          event_category: 'button',
          event_label: `Click - From: ${current} - To: ${href} - Text: ${text}`
        })
      })
    }
  }

  if (blockButtons.length) {
    for (let i = 0; i < blockButtons.length; i++) {
      blockButtons[i].addEventListener('click', () => {
        const text = blockButtons[i].innerText || blockButtons[i].textContent
        const href = blockButtons[i].href ? blockButtons[i].href : 'no-link'
        const current = window.location.href.replace(baseUrl, '')

        window.gtag('event', 'click', {
          event_category: 'block-button',
          event_label: `Click - From: ${current} - To: ${href} - Text: ${text}`
        })
      })
    }
  }

  const allCards = document.querySelectorAll('.l-projects--all .rounded-card')
  const featuredCards = document.querySelectorAll('.l-projects--featured .rounded-card')

  if (allCards.length) {
    for (let i = 0; i < allCards.length; i++) {
      allCards[i].addEventListener('click', () => {
        const title = allCards[i].querySelector('.rounded-card__title')
        const text = title.innerText || title.textContent

        window.gtag('event', 'click', {
          event_category: 'card',
          event_label: `Click - All - ${text}`
        })
      })
    }
  }

  if (featuredCards.length) {
    for (let i = 0; i < featuredCards.length; i++) {
      featuredCards[i].addEventListener('click', () => {
        const title = featuredCards[i].querySelector('.rounded-card__title')
        const text = title.innerText || title.textContent

        window.gtag('event', 'click', {
          event_category: 'card',
          event_label: `Click - Featured - ${text}`
        })
      })
    }
  }

  const menuItems = document.querySelectorAll('.menu-item')
  if (menuItems.length) {
    for (let i = 0; i < menuItems.length; i++) {
      menuItems[i].addEventListener('click', () => {
        const text = menuItems[i].innerText || menuItems[i].textContent

        window.gtag('event', 'click', {
          event_category: 'menu',
          event_label: `Click - Menu - ${text}`
        })
      })
    }
  }

  const footerTel = document.querySelector('.footer-tel')
  const footerMailto = document.querySelector('.footer-mailto')

  if (footerTel) {
    footerTel.addEventListener('click', () => {
      window.gtag('event', 'click', {
        event_category: 'contact',
        event_label: 'Click - Footer call'
      })
    })
  }

  if (footerMailto) {
    footerMailto.addEventListener('click', () => {
      window.gtag('event', 'click', {
        event_category: 'contact',
        event_label: 'Click - Footer mail'
      })
    })
  }

  const instaLinks = document.querySelectorAll('.l-instagram__posts a')

  if (instaLinks.length) {
    for (let i = 0; i < instaLinks.length; i++) {
      instaLinks[i].addEventListener('click', () => {
        window.gtag('event', 'click', {
          event_category: 'insta',
          event_label: `Click - Instagram - ${instaLinks[i].alt}`
        })
      })
    }
  }
})
