import Flickity from 'flickity'

const imagesLoaded = require('imagesloaded')

const createSlider = el => {
  const sliderEl = el.querySelector('[data-slider-slides]')
  const prevButton = el.querySelector('[data-slider-button="prev"]')
  const nextButton = el.querySelector('[data-slider-button="next"]')
  const currentIndexEl = el.querySelector('[data-slider-current]')
  const lastIndexEl = el.querySelector('[data-slider-last]')

  const flkty = new Flickity(sliderEl, {
    freeScroll: false,
    contain: true,
    percentPosition: false,
    prevNextButtons: false,
    pageDots: false,
    pauseAutoPlayOnHover: false,
    on: {
      ready () {
        const lastIndex = sliderEl.querySelectorAll('[data-slider-cell]').length
        lastIndexEl.innerHTML = lastIndex
        currentIndexEl.setAttribute('aria-label', `Last page index: ${lastIndex}`)
        prevButton.disabled = true

        imagesLoaded(sliderEl, () => {
          // console.log('images loaded')
          flkty.resize()
        })
      }
    }
  })

  prevButton.addEventListener('click', () => {
    flkty.previous()
  })

  nextButton.addEventListener('click', () => {
    flkty.next()
  })

  flkty.on('change', index => {
    currentIndexEl.innerHTML = index + 1

    prevButton.disabled = index === 0
    nextButton.disabled = index === flkty.slides.length - 1
  })
}

export default createSlider
