import docReady from '@/js/helpers/doc-ready'
import { scrollToY } from '@/js/helpers/scroll'
import gsap from 'gsap'
import scrollMonitor from 'scrollmonitor'

class RequestForm {
  constructor (el) {
    this.el = el
    this.radioElements = this.el.querySelectorAll('.rq__input--radio')
    this.checkboxElements = this.el.querySelectorAll('.rq__input--checkbox')
    this.textElements = this.el.querySelectorAll('.single-text__input')
    this.elseElements = this.el.querySelectorAll('.rq__else')
    this.buttonElements = this.el.querySelectorAll('.rq__button')
    this.sections = this.el.querySelectorAll('.rq__wrap')

    this.fields = {}
    this.gformFields = this.el.querySelector('.gform_fields')

    if (this.gformFields) {
      this.initEvents()
    } else {
      console.log('No gform fields available.')
    }
  }

  setForm () {
    if (this.fields) {
      const f = this.fields
      Object.keys(f).forEach(key => {
        const adminField = this.gformFields.querySelector(`.gfield.${key} input[type="text"]`)

        if (adminField) {
          adminField.value = f[key]
          console.log(`Data field with key: ${key} added!`, adminField.value)
        } else {
          console.log(`Data field with key: ${key} couldn't be found.`)
        }
      })
    }
  }

  changeCheckbox (el) {
    const val = el.value
    const name = el.name

    if (this.fields[name]) {
      const index = this.fields[name].indexOf(val)
      if (index > -1) {
        // Remove from array
        this.fields[name].splice(index, 1)
      } else {
        // Add to array
        this.fields[name].push(val)
      }

      if (!this.fields[name].length) {
        delete this.fields[name]
      }
    } else {
      this.fields[name] = []
      this.fields[name].push(val)
    }

    this.setForm()
  }

  changeRadio (el) {
    const val = el.value
    const name = el.name

    this.fields[name] = val

    this.setForm()
    this.nextSection(el)
  }

  scrollToNext (el) {
    const nextSection = el.closest('.row').nextElementSibling
    if (nextSection) {
      const rect = nextSection.getBoundingClientRect()
      scrollToY(rect.top + window.pageYOffset, 2000, 'easeOutSine', () => {
        const stInput = nextSection.querySelector('.single-text__input')
        if (stInput) {
          stInput.focus()
        }
      })
    }
  }

  nextSection (el, delay = 150) {
    const checkedLabels = document.querySelectorAll('input:checked + label, input.single-text__input')

    if (checkedLabels.length) {
      gsap.to(checkedLabels, {
        delay: 0.1,
        ease: 'power3.inOut',
        opacity: 0,
        yoyo: true,
        repeat: 3,
        duration: 0.15,
        onComplete: () => {
          setTimeout(() => {
            this.scrollToNext(el)
          }, delay)
        }
      })
    } else {
      this.scrollToNext(el)
    }
  }

  changeText (el) {
    const val = el.value
    const name = el.getAttribute('data-for')

    this.fields[name] = val

    this.setForm()

    this.nextSection(el)
  }

  changeElse (el) {
    const val = el.value
    const name = el.getAttribute('data-for')
    const parentField = el.parentNode.parentNode
    const multi = parentField.querySelector(`.rq__input--multi[name="${name}"]`)

    multi.value = val
    if (!multi.checked) {
      multi.click()
    }
  }

  initEvents () {
    if (this.radioElements.length) {
      for (let i = 0; i < this.radioElements.length; i++) {
        const radioElement = this.radioElements[i]
        radioElement.addEventListener('change', () => this.changeRadio(radioElement))
      }
    }

    if (this.checkboxElements.length) {
      for (let i = 0; i < this.checkboxElements.length; i++) {
        const checkboxElement = this.checkboxElements[i]
        checkboxElement.addEventListener('change', () => this.changeCheckbox(checkboxElement))
      }
    }

    if (this.elseElements.length) {
      for (let i = 0; i < this.elseElements.length; i++) {
        const elseElement = this.elseElements[i]
        elseElement.addEventListener('change', () => this.changeElse(elseElement))
      }
    }

    if (this.textElements.length) {
      for (let i = 0; i < this.textElements.length; i++) {
        const textElement = this.textElements[i]
        textElement.addEventListener('change', () => this.changeText(textElement))
      }
    }

    if (this.buttonElements.length) {
      for (let i = 0; i < this.buttonElements.length; i++) {
        const buttonElement = this.buttonElements[i]
        buttonElement.addEventListener('click', () => this.nextSection(buttonElement, 0))
      }
    }

    if (this.sections.length) {
      for (let i = 0; i < this.sections.length; i++) {
        const sectionElement = this.sections[i]
        const elementWatcher = scrollMonitor.create(sectionElement, -200)

        elementWatcher.enterViewport(function () {
          gsap.to(sectionElement, {
            opacity: 1,
            duration: 0.4
          })
          sectionElement.classList.add('rq__wrap--active')
        })
        elementWatcher.exitViewport(function () {
          gsap.to(sectionElement, {
            opacity: 0,
            duration: 0.4
          })
          sectionElement.classList.remove('rq__wrap--active')
        })
      }
    }
  }
}

docReady(() => {
  const rq = document.querySelector('.rq')
  if (rq) {
    // eslint-disable-next-line
    const requestForm = new RequestForm(rq)
  }
})
