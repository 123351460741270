// Modernizr
import 'root/.modernizrrc'

// Stylesheets
import '@/scss/style'

// Components
import '@/js/components/break'
import '@/js/components/menu'
// import '@/js/components/instafeed'
import '@/js/components/intro'
import '@/js/components/animsition'
import '@/js/components/monitor'
import '@/js/components/slider'
import '@/js/components/tilt'
import '@/js/components/events'
import '@/js/components/map'
import '@/js/components/request'
