import scrollMonitor from 'scrollmonitor'
import gsap from 'gsap'
import { BasicTilt } from '@/js/components/tilt'

const CustomEase = (function () {
  function CustomEase (_name, segments) {
    this._name = _name
    this._segments = []
    for (let i = 0; i < segments.length; i++) {
      this._segments[this._segments.length] = new Segment(segments[i].s, segments[i].cp, segments[i].e)
    }
    CustomEase._all[_name] = this
  }

  CustomEase._all = {
  }

  CustomEase.create = function create (name, segments) {
    const b = new CustomEase(name, segments)
    return b.ease.bind(b)
  }

  CustomEase.byName = function byName (name) {
    return CustomEase._all[name].ease
  }

  CustomEase.prototype.ease = function (time, start, change, duration) {
    const factor = time / duration
    const qty = this._segments.length
    const i = Math.floor(qty * factor)
    const t = (factor - (i * (1 / qty))) * qty
    const s = this._segments[i]

    return start + change * (s.s + t * (2 * (1 - t) * (s.cp - s.s) + t * (s.e - s.s)))
  }

  CustomEase.prototype.destroy = function () {
    this._segments = null
    delete CustomEase._all[this._name]
  }

  return CustomEase
})()

class Segment {
  constructor (s, cp, e) {
    this.s = s
    this.cp = cp
    this.e = e
  }
}

(function () {
  CustomEase.create('smoothInOut', '0.275, 0.420, 0.000, 1.000')
  CustomEase.create('slide', '.8,.14,.24,.99')
  const duration = 0.3

  const els = document.getElementsByClassName('watch-element')
  if (els) {
    for (let i = 0; i < els.length; i++) {
      const el = els[i]
      const maskEls = el.querySelectorAll('.mask-in')

      for (let j = 0; j < maskEls.length; j++) {
        const maskEl = maskEls[j]

        const maskElements = {}

        // Base timeline
        const timeline = gsap.timeline()

        // If there's a mask
        if (maskEl) {
          // Add to maskElements object
          maskElements.mask = maskEl.querySelector('.mask-in__mask')
          maskElements.fade = maskEl.querySelector('.mask-in__fade')

          // Set standards
          gsap.set(maskElements.fade, {
            opacity: 0,
            scale: 1.075,
            force3D: !0
          })

          gsap.set(maskElements.mask, {
            y: '100%',
            force3D: !0
          })
        }

        // Create watcher
        const elWatcher = scrollMonitor.create(el, 0)
        const showElement = () => {
          el.classList.add('is-in-viewport')

          // If there's a mask
          if (maskEl) {
            if (maskElements.mask) {
              // Slide the mask in
              timeline.to(maskElements.mask, {
                y: '0%',
                ease: 'slide',
                duration: duration,
                onComplete () {
                  if (maskElements.fade) {
                    // Remove opacity from fade element
                    gsap.set(maskElements.fade, {
                      clearProps: 'opacity'
                    })
                  }

                  // Slide the mask out
                  gsap.to(maskElements.mask, {
                    y: '-101%',
                    ease: 'slide',
                    duration
                  })
                }
              })
            } else {
              console.warn('Monitor.js - Add a mask')
            }

            if (maskElements.fade) {
              // Transform the fade element
              timeline.to(maskElements.fade, {
                scale: 1,
                ease: 'smoothInOut',
                clearProps: 'scale',
                duration: 1.1,
                onComplete () {
                  el.classList.add('is-completed')
                  // And reset back to normal
                  gsap.set(maskElements.fade, {
                    clearProps: 'transform'
                  })

                  const tiltingEls = el.querySelectorAll('.rounded-card')

                  if (tiltingEls) {
                    for (let i = 0; i < tiltingEls.length; i++) {
                      // eslint-disable-next-line
                      const tilt = new BasicTilt(tiltingEls[i])
                    }
                  }
                }
              }, duration / 2)
            } else {
              console.warn('Monitor.js - Add a fade')
            }
          }

          if (maskElements.mask) {
            timeline.eventCallback('onComplete', () => {
              maskElements.mask.classList.add('hidden')
              maskElements.mask.parentNode.removeChild(maskElements.mask)
            })
          }

          // Destroy the watcher
          elWatcher.destroy()
        }

        // Do when element enters viewport
        elWatcher.exitViewport(showElement)
        elWatcher.enterViewport(showElement)
      }
    }
  }
})()
