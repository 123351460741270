import gsap from 'gsap'
import throttle from '@/js/helpers/throttle'

// Gets the mouse position
export const getMousePos = e => {
  let posx = 0
  let posy = 0
  if (!e) {
    e = window.event
  }
  if (e.pageX || e.pageY) {
    posx = e.pageX
    posy = e.pageY
  } else if (e.clientX || e.clientY) {
    posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
    posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop
  }
  return {
    x: posx,
    y: posy
  }
}

class Tilt {
  constructor (el) {
    this.DOM = { el }
    // The following elements will tilt and scale
    this.DOM.scale = {}
    this.scaleConfig = {}
    this.DOM.tilt = {}
    this.initEvents()
  }

  initEvents () {
    this.toggleAnimationOnHover = type => {
      // Scale up the scale elements
      for (const key in this.DOM.scale) {
        const s = this.scaleConfig[key]
        if (this.DOM.scale[key]) {
          gsap.to(this.DOM.scale[key], {
            ease: 'expo.out',
            duration: 1,
            scale: type === 'mouseenter' ? s.in : s.out
          })
        }
      }
    }

    /**
    * Mouseenter:
    * - Scale up the DOM.bg element
    * - Animate the role letters
    *
    * Mousemove:
    * - tilt - move all tilt elements
    *
    * Mouseleave:
    * - Scale down the DOM.bg element
    * - Animate the role letters
    */
    this.mouseenterFn = e => this.toggleAnimationOnHover(e.type)
    this.mousemoveFn = throttle(e => {
      this.tilt(e)
    }, 33)
    this.mouseleaveFn = e => this.toggleAnimationOnHover(e.type)
    if (window.matchMedia('(min-width: 768px)').matches) {
      this.DOM.el.addEventListener('mouseenter', this.mouseenterFn)
      this.DOM.el.addEventListener('mousemove', this.mousemoveFn)
      this.DOM.el.addEventListener('mouseleave', this.mouseleaveFn)
    }
  }

  tilt (e) {
    // Get mouse position
    const mousepos = getMousePos(e)
    // Document scrolls
    const docScrolls = {
      left: document.body.scrollLeft + document.documentElement.scrollLeft,
      top: document.body.scrollTop + document.documentElement.scrollTop
    }
    const bounds = this.DOM.el.getBoundingClientRect()
    // Mouse position relative to the main element (this.DOM.el)
    const relmousepos = {
      x: mousepos.x - bounds.left - docScrolls.left,
      y: mousepos.y - bounds.top - docScrolls.top
    }
    // Movement settings for the tilt elements
    for (const key in this.DOM.tilt) {
      // Animate each of the elements
      if (this.DOM.tilt[key]) {
        gsap.to(this.DOM.tilt[key], {
          ease: 'expo.out',
          duration: 2,
          x: (20 / bounds.width * relmousepos.x - 10) * -1,
          y: (20 / bounds.height * relmousepos.y - 10) * -1
        })
      }
    }
  }
}

export class BasicTilt extends Tilt {
  constructor (el) {
    super(el)
    this.DOM.scale.item = this.DOM.el
    this.scaleConfig = {
      item: {
        in: 1.04,
        out: 1
      }
    }
    this.DOM.tilt.item = this.DOM.el
  }
}
