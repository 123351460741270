import docReady from '@/js/helpers/doc-ready'

const addAccessibility = el => {
  const ariaLabel = el.textContent
  const ariaSpan = `<span class="sr-only">${ariaLabel}</span>`
  el.insertAdjacentHTML('beforebegin', ariaSpan)
  el.setAttribute('aria-hidden', 'true')
}

const breakSpans = el => {
  addAccessibility(el)

  if (el.querySelectorAll('[class^=fake-]').length) {
    return
  }

  const sentenceArr = el.innerHTML.split('<br>')
  const newSentenceArr = []

  for (let i = 0; i < sentenceArr.length; i++) {
    const wordsArr = sentenceArr[i].split(' ')
    const newWordArr = []

    for (let i = 0; i < wordsArr.length; i++) {
      newWordArr.push(`<span class="word-holder"><span class="word-holder__animate">${wordsArr[i]}</span></span>`)
    }

    newSentenceArr.push(newWordArr.join(' '))
  }

  el.innerHTML = newSentenceArr.join('<br>')
}

docReady(() => {
  const spans = document.querySelectorAll('.break-in-spans')

  for (let i = 0; i < spans.length; i++) {
    breakSpans(spans[i])
  }
})
