import gsap from 'gsap'
import docReady from '@/js/helpers/doc-ready'

class Revealer {
  constructor (el, options = { angle: 0 }) {
    this.options = options
    this.DOM = {}
    this.DOM.el = el
    this.DOM.layers = this.DOM.el.querySelectorAll('[data-move]')
    this.DOM.reverse = this.DOM.el.querySelector('[data-reverse]')
    this.DOM.main = document.querySelector('main')
    this.DOM.text = document.querySelector('[data-text]')

    const sum = this.options.angle * Math.PI / 180
    const sin = Math.abs(Math.sin(sum))
    const cos = Math.abs(Math.cos(sum))
    const width = `calc(100vw * ${cos} + 100vh * ${sin})`
    const height = `calc(100vw * ${sin} + 100vh * ${cos})`

    for (let i = 0; i < this.DOM.layers.length; i++) {
      const layer = this.DOM.layers[i]
      const hasReverse = !!layer.querySelector('[data-reverse]')

      if (layer) {
        const rotation = hasReverse ? this.options.angle : 0 - this.options.angle

        layer.style.width = width
        layer.style.height = height
        layer.parentNode.style.transform = `rotate3d(0,0,1,${rotation}deg)`
      }
      if (hasReverse) {
        gsap.set(this.DOM.reverse, { rotation: -1 * this.options.angle })
      }
    }

    if (this.DOM.text) {
      gsap.set(this.DOM.text, {
        opacity: 0,
        scale: 0.95,
        rotationX: -45,
        y: '100%'
      })
    }

    this.initEvents()
  }

  animate () {
    const ease = 'expo.inOut'
    const duration = 1.2
    const delay = 0.5

    const timeline = gsap.timeline()

    if (this.DOM.text) {
      timeline.to(this.DOM.text, {
        ease,
        opacity: 1,
        rotationX: 0,
        scale: 1,
        y: '0%',
        duration: 0.25
      }, delay)
    }

    if (this.DOM.layers) {
      timeline.to(this.DOM.layers, {
        ease,
        y: '-100%',
        stagger: 0.2,
        duration
      }, delay + 0.5)
    }

    if (this.DOM.reverse) {
      timeline.to(this.DOM.reverse, {
        ease,
        y: '100%',
        duration
      }, delay + 0.5)
    }

    if (this.DOM.main) {
      timeline.fromTo(this.DOM.main, {
        y: 100,
        opacity: 0
      }, {
        y: 0,
        opacity: 1,
        duration,
        ease
      }, delay + 0.2)
    }

    this.pageToggleTimeline = timeline
    this.pageToggleTimeline.eventCallback('onComplete', () => {
      this.DOM.el.parentNode.removeChild(this.DOM.el)
    })
  }

  initEvents () {
    this.animate()
  }
}

docReady(() => {
  const introFirst = document.querySelector('.intro')
  if (introFirst) {
    // eslint-disable-next-line
    const revealer = new Revealer(introFirst, { angle: -16 })
  }
})
