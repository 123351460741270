import throttle from '@/js/helpers/throttle'
import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'

class Menu {
  constructor () {
    this.body = document.body
    this.hamburgers = this.body.querySelectorAll('.nav-mobile__hamburger')
    this.wrapper = this.body.querySelector('.wrapper')
    this.menus = this.body.querySelectorAll('.nav-mobile, .nav-desktop')
    this.slide = this.body.querySelector('.nav-mobile__slide')
    this.toggleElements = this.body.querySelectorAll('.navbar-toggle')
    this.toast = this.body.querySelector('.toast')

    this.initEvents()
  }

  menuSlideIn () {
    for (let i = 0; i < this.hamburgers.length; i++) {
      this.hamburgers[i].classList.remove('nav-mobile__hamburger--is-active')
    }
    this.wrapper.classList.add('wrapper--slide')
  }

  toggleMenu () {
    // And toggle the active class
    this.body.classList.toggle('body--slide-active')

    if (this.body.classList.contains('body--slide-active')) {
      for (let i = 0; i < this.menus.length; i++) {
        this.menus[i].classList.add('nav-mobile--active')
      }

      for (let i = 0; i < this.hamburgers.length; i++) {
        this.hamburgers[i].classList.add('nav-mobile__hamburger--is-active')
      }
    } else {
      for (let i = 0; i < this.menus.length; i++) {
        this.menus[i].classList.remove('nav-mobile--active')
      }
      this.menuSlideIn()
    }
  }

  headerScroll (ws) {
    const isScrolled = ws > 30
    this.body.classList.toggle('menu-scroll', isScrolled)

    for (let i = 0; i < this.menus.length; i++) {
      this.menus[i].classList.toggle('menu-scroll', isScrolled)
    }
  }

  headerToast (ws) {
    const isScrolled = ws > 30
    const rect = this.toast.getBoundingClientRect()

    gsap.to([this.menus, this.slide], {
      top: isScrolled ? 0 : rect.height,
      duration: 0.6,
      ease: 'power3.out'
    })

    gsap.to(this.toast, {
      y: isScrolled ? 0 - rect.height : 0,
      duration: 0.6,
      ease: 'power3.out'
    })
  }

  initEvents () {
    const clickHandler = () => this.toggleMenu()

    for (let i = 0; i < this.toggleElements.length; i++) {
      this.toggleElements[i].addEventListener('click', clickHandler)
    }

    window.addEventListener('scroll', throttle(() => {
      const ws = window.pageYOffset
      this.headerScroll(ws)

      if (this.toast) {
        this.headerToast(ws)
      }
    }, 100))

    this.headerScroll(0)

    if (this.toast) {
      this.headerToast(0)
      // const rect = this.toast.getBoundingClientRect()
      // gsap.set(this.wrapper, {
      //   y: rect.height
      // })
    }
  }
}

docReady(() => {
  // eslint-disable-next-line
  const menu = new Menu()
})
