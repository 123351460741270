import { getRandomArbitrary } from '@/js/helpers/math'
import gsap from 'gsap'
import docReady from '@/js/helpers/doc-ready'

class PageAnimation {
  constructor (el) {
    this.el = el
    this.options = {
      inClass: 'overlay-slide-in-bottom',
      outClass: 'overlay-slide-out-bottom',
      inDuration: 400,
      outDuration: 800,
      overlayClass: 'animsition-overlay-slide',
      overlayParentElement: document.body,
      linkElements: document.querySelectorAll('a:not([target="_blank"]):not([href^="#"]):not([href^="mailto:"]):not([href^="tel:"])'),
      wordsEls: document.querySelectorAll('.word-holder')
    }

    this.init()
  }

  addOverlay () {
    this.options.overlayParentElement.insertAdjacentHTML('afterBegin', `<div class="${this.options.overlayClass}"></div>`)
  }

  inStart () {
    for (let i = 0; i < this.options.wordsEls.length; i++) {
      setTimeout(() => {
        this.options.wordsEls[i].classList.add('word-holder--done')
      }, (200 * i) + getRandomArbitrary(1, 200))
    }
  }

  inEnd () {
    // console.log('inEnd')
  }

  outEnd () {
    // console.log('outEnd')
  }

  inOverlay () {
    gsap.set(this.el, {
      opacity: 1
    })

    this.inStart()

    const parChildren = this.options.overlayParentElement.querySelectorAll('.' + this.options.overlayClass)
    if (parChildren) {
      gsap.set(parChildren, {
        'animation-duration': `${this.options.inDuration}ms`
      })
      for (let i = 0; i < parChildren.length; i++) {
        parChildren[i].classList.add(this.options.inClass)
        this.inEnd()
      }
    }
  }

  animateIn () {
    this.el.classList.remove(this.options.outClass)
    this.inOverlay(this.options.inClass, this.options.inDuration)
  }

  outFn (url = window.location.href) {
    const parChildren = this.options.overlayParentElement.querySelectorAll('.' + this.options.overlayClass)
    if (parChildren) {
      gsap.set(parChildren, {
        'animation-duration': `${this.options.outDuration}ms`
      })

      for (let i = 0; i < parChildren.length; i++) {
        parChildren[i].classList.remove(this.options.inClass)
        parChildren[i].classList.add(this.options.outClass)
      }

      setTimeout(() => {
        this.outEnd()
        window.location.href = url
      }, this.options.outDuration)
    }
  }

  init () {
    this.addOverlay()

    const loadHandler = () => this.animateIn()

    loadHandler()

    window.addEventListener('pageshow', e => {
      if (e.persisted) {
        this.outFn()
        this.animateIn()
      }
    })

    const outHandler = url => this.outFn(url)

    for (let i = 0; i < this.options.linkElements.length; i++) {
      this.options.linkElements[i].addEventListener('click', function (e) {
        e.preventDefault()
        const url = this.href

        if (e.which === 2 || e.metaKey || e.shiftKey || (navigator.platform.toUpperCase().indexOf('WIN') !== -1 && e.ctrlKey)) {
          window.open(url, '_blank')
        } else {
          outHandler(url)
        }
      })
    }
  }
}

docReady(() => {
  const el = document.querySelectorAll('.animsition-overlay')
  if (el.length) {
    // console.log(el[0])
    // eslint-disable-next-line
    const pageAnim = new PageAnimation(el[0])
  }
})
